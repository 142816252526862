<template>
    <a-modal v-model="show" title="员工信息" :width="700" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
        <a-form-model ref="formRef" :model="formData" :rules="formRule">
            <a-row :gutter="15">
                <a-col span="6">
                    <a-form-model-item label="姓名" label-position="top" prop="name">
                        <a-input v-model="formData.name" placeholder="请输入姓名"></a-input>
                    </a-form-model-item>
                </a-col>
                <a-col span="6" v-if="getOrganId() == 0">
                    <a-form-model-item label="所属单位" label-position="top" prop="organId">
                        <a-select v-model="formData.organId" style="width:100%" placeholder="请选择单位" @change="organIdChange">
                            <a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{
                                organ.name }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col span="6">
                    <a-form-model-item label="登录账号" label-position="top" prop="username">
                        <a-input v-model="formData.username" placeholder="请输入登录账号"></a-input>
                    </a-form-model-item>
                </a-col>
                <a-col span="6">
                    <a-form-model-item label="登录密码" label-position="top" prop="password">
                        <a-input v-model="formData.password" type="password" placeholder="请输入登录密码"></a-input>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="15" v-if="formData.organId">
                <a-col span="6">
                    <a-form-model-item label="主角色" label-position="top" prop="roleId">
                        <select-role v-model="formData.roleId" :organId="formData.organId"></select-role>
                    </a-form-model-item>
                </a-col>
                <a-col span="18">
                    <a-form-model-item label="辅助角色" label-position="top" prop="subRoleIds">
                        <select-role v-model="formData.subRoleIds" :organId="formData.organId" multiple></select-role>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="15" v-if="formData.organId">
                <a-col span="6">
                    <a-form-model-item label="主部门" label-position="top" prop="depId">
                        <select-depart v-model="formData.depId" :organId="formData.organId"></select-depart>
                    </a-form-model-item>
                </a-col>
                <a-col span="18">
                    <a-form-model-item label="辅助部门" label-position="top" prop="subDepIds">
                        <select-depart v-model="formData.subDepIds" multiple :organId="formData.organId"></select-depart>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="15">
                <a-col span="6">
                    <a-form-model-item label="管理范围" label-position="top" prop="managerScope">
                        <a-select v-model="formData.managerScope">
                            <a-select-option value="本部门">本部门</a-select-option>
                            <a-select-option value="全体">全体</a-select-option>
                            <a-select-option value="指定部门">指定部门</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col span="6">
                    <a-form-model-item label="手机号" label-position="top" prop="phone">
                        <a-input v-model="formData.phone" type="number" placeholder="请输入手机号"></a-input>
                    </a-form-model-item>
                </a-col>
                <a-col span="6">
                    <a-form-model-item label="公开手机号" label-position="top" prop="publicPhone" required>
                        <a-radio-group v-model="formData.publicPhone" button-style="solid">
                            <a-radio-button :value="true">公开</a-radio-button>
                            <a-radio-button :value="false">不公开</a-radio-button>
                        </a-radio-group>
                    </a-form-model-item>
                </a-col>
                <!-- <a-col span="6">
                    <a-form-model-item label="个人空间容量" label-position="top" prop="spaceSize">
                        <a-input v-model="formData.spaceSize" placeholder="不限制" :min="0" type="number">
                            <span slot="suffix">MB</span>
                        </a-input>
                    </a-form-model-item>
                </a-col> -->
                <!-- <a-col span="6">
                    <a-form-model-item label="个人职务" label-position="top" prop="spaceSize">
                        <a-input v-model="formData.duties">

                        </a-input>
                    </a-form-model-item>
                </a-col> -->
                <a-col span="6">
                    <a-form-model-item label="账户状态" label-position="top" prop="status" required>
                        <a-select v-model="formData.status">
                            <a-select-option value="启用">启用</a-select-option>
                            <a-select-option value="禁用">禁用</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="15" v-if="formData.managerScope == '指定部门'">
                <a-col span="24">
                    <a-form-model-item label="管理部门" label-position="top" prop="managerDepIds">
                        <select-depart v-model="formData.managerDepIds" :organId="formData.organId" multiple
                                       isAll></select-depart>
                    </a-form-model-item>
                </a-col>
            </a-row>

        </a-form-model>
        <a-spin size="large" fix v-if="loading" />
    </a-modal>
</template>

<script>
import request from "@/config/request";
import utils from "@/common/utils";
import apiUtil from "@/common/apiUtil";
export default {
    data() {
        return {
            show: false,
            loading: false,
            organList: apiUtil.getOrganList(),
            formData: {
                staffId: '',
                name: '',
                depId: '',
                organId: apiUtil.getOrganId(''),
                subDepIds: [],
                roleId: '',
                subRoleIds: [],
                phone: '',
                publicPhone:true,
                username: '',
                password: '',
                managerScope: '本部门',
                managerDepIds: [],
                spaceSize: 1000,
                status: '启用'
            },
            formRule: {
                name: [{ required: true, message: '请输入姓名' }],
                organId: [{ required: true, message: '请选择所属单位' }],
                username: [{ required: true, message: '请输入登录账号' }],
                password: [{ required: true, message: '请输入登录密码' }],
                depId: [{ required: true, message: '请选择所属部门' }],
                roleId: [{ required: true, message: '请选择角色' }],
                managerDepIds: [{ required: true, message: '请选择管理范围' }]
            }
        };
    },
    methods: {
        ...apiUtil,
        _show(form) {
            this.show = true;
            this.$nextTick(() => {
                this.$refs.formRef.resetFields();
                if (form) {
                    this.formData = JSON.parse(JSON.stringify(form));
                    console.log(this.formData);
                } else {
                    this.formData.staffId = '';
                    this.formData.managerDepIds = [];
                    this.formData.subRoleIds = [];
                    this.formData.subDepIds = [];
                    this.formData.depId = '';
                    this.formData.roleId = '';
                    this.formData.organId = apiUtil.getOrganId('');
                    this.formData.phone = '';
                    this.formData.managerScope = '本部门';
                }
            });
        },
        _close() {
            this.$refs.formRef.resetFields();
            this.show = false;
            this.$emit('close');
        },
        // 切换单位，清空一些数据
        organIdChange() {
            this.formData.managerDepIds = [];
            this.formData.subRoleIds = [];
            this.formData.subDepIds = [];
            this.formData.depId = '';
            this.formData.roleId = '';

        },
        doSubmit() {
            this.$refs.formRef.validate((valid) => {
                if (valid) {
                    // console.log(this.formData.managerDepIds); return;
                    utils.showSpin();
                    request.post('/platform/staff/save', this.formData).then(ret => {
                        // console.log(ret)
                        this.$refs.formRef.resetFields();
                        this.show = false;
                        if (ret.code == 200) {
                            utils.success('操作成功！').then(() => {
                                this.show = false;
                                this.$emit('success');
                            });
                        }
                    });
                }
            });
        }
    }
};
</script>

<style scoped>
.demo-drawer-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
}
</style>
